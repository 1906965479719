import React from 'react';
import ReactDOM from 'react-dom/client';
import LoadingModuleGIF from 'assets/timhut-module-loading.gif'

let root = null;

const Render = (props) => {
  const { loading } = props;

  return (
    <>
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          background: "white",
          opacity: loading ? 1 : 0,
          visibility: loading ? "visible" : "hidden",
          transition: "opacity 1s ease-in-out, visibility 1s ease-in-out",
          zIndex: 100,
        }}
      >
        <div
          style={{
            width: "100%",
            height: "calc(100vh - 16px)",
            margin: 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h4 className="subapp-loading">
            <img src={LoadingModuleGIF} alt="this slowpoke moves"  width="250" />
          </h4>
          <span>Loading</span>
        </div>
      </div>
      <div id="subapp" />
    </>
  );
}

export default function render({ loading }) {
  const container = document.getElementById('rootapp');
  
  if (!root) {
    // Create the root if it doesn't exist
    root = ReactDOM.createRoot(container);
  }
  
  // Use the existing root to render
  root.render(<Render loading={loading} />);
}